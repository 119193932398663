var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-1 mb-10"},[_c('Title',{staticClass:"mt-1 mt-xl-5"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_vm._v(" "+_vm._s(_vm.$t('groups.member_list.invited.title'))+" "),_c('span',{staticClass:"count"},[_vm._v(" "+_vm._s(_vm.$t('groups.member_list.guest_count', { count: _vm.invitedMember.length }))+" ")])])]),_c('div',{staticClass:"content bg-body-primary pt-xl-5 border-bottom-left-right-radius"},[(!_vm.invitedMember.length)?_c('div',{staticClass:"text-center text-secondary py-5"},[_vm._v(" "+_vm._s(_vm.$t('groups.member_list.invited.no_invited_member'))+" ")]):_c('div',{staticClass:"w-xl-50 pb-15"},_vm._l((_vm.invitedMember),function(member){return _c('div',{key:member.id},[_c('div',{staticClass:"d-flex align-items-center justify-content-between px-3 px-xl-5"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"flex-fixed h--9 w--9 my-2"},[(member.invite_type === _vm.eIviteBy.LINK && member.avatar)?_c('img',{staticClass:"rounded-pill img-cover",attrs:{"src":member.avatar}}):_vm._e(),(member.invite_type === _vm.eIviteBy.LINK && !member.avatar)?_c('img',{staticClass:"rounded-pill img-cover",attrs:{"src":_vm.noAvatar}}):_vm._e()]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex flex-column ps-3"},[(member.invite_type === _vm.eIviteBy.LINK)?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
                    customClass: _vm.handleLongText(member.email).isLong
                      ? 'd-block'
                      : 'd-none'
                  }),expression:"{\n                    customClass: handleLongText(member.email).isLong\n                      ? 'd-block'\n                      : 'd-none'\n                  }",modifiers:{"hover":true}}],staticClass:"text-primary cursor-pointer",attrs:{"title":member.email},on:{"click":function($event){return _vm.showMemberProfile(member.user_id)}}},[_vm._v(" "+_vm._s(_vm.handleLongText(member.email).text)+" ")]):_vm._e(),(member.invite_type === _vm.eIviteBy.MAIL)?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
                    customClass: _vm.handleLongText(member.email).isLong
                      ? 'd-block'
                      : 'd-none'
                  }),expression:"{\n                    customClass: handleLongText(member.email).isLong\n                      ? 'd-block'\n                      : 'd-none'\n                  }",modifiers:{"hover":true}}],attrs:{"title":member.email}},[_vm._v(" "+_vm._s(_vm.handleLongText(member.email).text)+" ")]):_vm._e(),(member.is_expert)?_c('span',{staticClass:"text-secondary fs-10 fs-xl-12"},[_vm._v(" "+_vm._s(_vm.$t('groups.member_list.invited.expired'))+" ")]):_vm._e(),(!member.is_expert)?_c('span',{staticClass:"text-secondary fs-10 fs-xl-12"},[_vm._v(" "+_vm._s(_vm.$t('groups.member_list.invited.date_invite', { date: member.inviteDateVM }))+" ")]):_vm._e()]),(member.invite_type === _vm.eIviteBy.LINK)?_c('span',{staticClass:"px-3 text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t('groups.member_list.name_suffix'))+" ")]):_vm._e()])]),_c('div',{staticClass:"d-flex align-items-center"},[(member.is_expert)?_c('button',{staticClass:"btn btn-primary text-nowrap me-5 px-3 button-border-radius",on:{"click":function($event){return _vm.reInviteMember(member)}}},[_vm._v(" "+_vm._s(_vm.$t('groups.member_list.invited.re_invite'))+" ")]):_vm._e(),_c('div',{on:{"click":function($event){return _vm.openDelInvite(member)}}},[(member.is_expert)?_c('span',{staticClass:"text-nowrap text-primary cursor-pointer"},[_vm._v(" "+_vm._s(_vm.$t('groups.member_list.invited.delete'))+" ")]):_vm._e(),(!member.is_expert)?_c('span',{staticClass:"text-nowrap text-primary cursor-pointer"},[_vm._v(" "+_vm._s(_vm.$t('groups.member_list.invited.cancel'))+" ")]):_vm._e()])])]),_c('div',{staticClass:"border-top-xl border-secondary mx-5"})])}),0)]),_c('ModalShowProfile',{attrs:{"userId":_vm.selectedUserId}}),_c('ModalConfirm',{attrs:{"confirmMess":_vm.$t('common.message.del_stop_invite')},on:{"yes":_vm.handleDelInvite}}),_c('ModalError',{attrs:{"errorMess":_vm.modalMess}}),_c('ModalSuccess',{attrs:{"successMess":_vm.modalMess}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }